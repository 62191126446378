import React, { useState, useEffect } from "react";
import "./CarouselComponent.css";
import { useMediaQuery } from "react-responsive";

const PartnerComponent = (props) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 1000px)" });
  const MAX_CAROUSEL = isMobileView ? props.max * 0.52 : (props.max);
  
  let partnersArray = [];

  for (let i = 1; i <= 25; i++) {
    partnersArray.push(
      `/images/${props.folder}s/${props.folder}${i}.png`
    )
  }

  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [isInitialized, setInitialized] = useState(false);
  
  useEffect(() => {
    if (!isInitialized) {
      changeImages()
      setInitialized(true)
    }

    const interval = setInterval(() => {
      changeImages()
    }, 2000)

    return () => clearInterval(interval);
  },)

  const changeImages = () => {
    let tempArray = []
    for (let i = index; i < index + MAX_CAROUSEL; i++) {
      //console.log(i < partnersArray.length ? i : i - partnersArray.length)
      tempArray.push(
        <div className="partners-img" key={i}>
          <img
            className={props.stylePartner ? "negative":""}
            src={partnersArray[i < partnersArray.length ? i : i - partnersArray.length + 1]}
            key={i < partnersArray.length ? i : i - partnersArray.length + 1} />
        </div>
      )
    }
    setIndex(index + 1 < partnersArray.length ? index + 1 : index - partnersArray.length + 1)

    setImages(tempArray)
  }

  return (
    <div className={props.stylePartner ? "partners-container":" partners-container"}>
      <div className="partners-grid">
        {images}
      </div>
    </div>
  );
};

export default PartnerComponent;
