import React from 'react'
import "./Main_7.css"
import main_7_1 from "../../../Images/main_images/main_7_1.jpg"
import { useState } from 'react'
import InputBox from './InputBox'
import Constants from '../../../Utils/Constants.json'


const Main_7 = () => {


  const expresiones = {
    usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
    nombre: /^[a-zA-ZÀ-ÿ\s]{5,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/,
    telefono: /^\d{10,10}$/ // 7 a 14 numeros.
  }

  const [nameClient, setNameClient] = useState({ campo: "", valido: true })
  const [emailClient, setEmailClient] = useState({ campo: "", valido: true })
  const [phoneClient, setPhoneClient] = useState({ campo: "", valido: true })
  const [formValid, setFormValid] = useState(null)
  const celphoneClient = Constants.contact_number

  const onSubmit = (e) => {
    e.preventDefault();

    if (nameClient.valido === true && emailClient.valido === true && phoneClient.valido === true) {
      setFormValid(true);
      if (window.confirm("Datos verificados, haga click en Aceptar.")) {
        window.open("https://wa.me/+57" +
          celphoneClient + "?text=Hola,%20mi%20nombre%20es%20" +
          nameClient.campo + ",%20tengo%20un%20proyecto%20en%20mente.%0AMi%20correo%20es%20" +
          emailClient.campo + "%20y%20mi%20numero%20celular%20es%20" +
          phoneClient.campo + ".%20%0ASaludos");
      }
      setNameClient({ campo: "", valido: true })
      setEmailClient({ campo: "", valido: true })
      setPhoneClient({ campo: "", valido: true })
    } else {
      setFormValid(false);

    }

  }

  return (
    <div className="container-form">

      <div className="form-image" style={{ flex: 7 }}>

        <img src={main_7_1} alt="imagen form" />

        <div className="left-gradient-form">
          <p className='title center-text'>¿Tienes una idea en mente?</p>
          <p className='subtitle'>Llena el formulario con tus datos y deja que nos preocupemos del resto. Podrás comunicarte con nuestra línea de expertos en WhatsApp.</p>
        </div>


      </div>
      <div className="form-fields" style={{ flex: 7 }}>

        <div className="form-title">
          <p className="title white">Contáctanos</p>
          <p className="subtitle white">Envíanos tu información para contactarte cuánto antes.</p>
        </div>

        <div className="form-fields">
          <form action="" onSubmit={onSubmit} id="formClientId">
            <InputBox
              estado={nameClient}
              cambiarEstado={setNameClient}
              tipo="text"
              placeHolder="Nombres y apellidos"
              expresionRegular={expresiones.nombre}
              valido={nameClient.valido}
              errorMessage="Por favor, ingrese su nombre completo."
            />
            <InputBox
              estado={emailClient}
              cambiarEstado={setEmailClient}
              tipo="email"
              placeHolder="Correo electrónico"
              expresionRegular={expresiones.correo}
              valido={emailClient.valido}
              errorMessage="Por favor, ingrese un correo electrónico válido."
            />
            <InputBox
              estado={phoneClient}
              cambiarEstado={setPhoneClient}
              tipo="text"
              placeHolder="Teléfono celular"
              expresionRegular={expresiones.telefono}
              valido={phoneClient.valido}
              errorMessage="Por favor, ingrese un número de celular válido."
            />
          </form>
<br />
          <button className="buttonn subtitle" type="submit" form='formClientId' >Contáctanos</button>
          <br />
          {formValid === false && <div className='error-message'>"Verifique los datos e intente de nuevo"</div>}


        </div>
      </div>

    </div>
  )
}

export default Main_7