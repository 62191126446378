import React from 'react'
import './Main_6.css'
import Faq from './Faq'
import {Link} from 'react-router-dom'

const Main_6 = () => {
    const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

    let faqInfo = [
        {
            title: "¿Qué tipo de servicios ofrece CAMNET?",
            text: "Nos enfocamos principalmente en el desarrollo de infraestructura para redes, desarrollo de sistemas complejos de medición de variables, monitoreo por medio de cámaras, plataformas digitales para negocios complejos y mucho más. Si tienes alguna duda en concreto sobre nuestros servicios puedes dirigirte al apartado Servicios o contactarnos por medio del formulario que encontrarás en el apartado de Contacto."
        },
        {
            title: "¿Puede CAMNET ayudarme a crear mi sitio web?",
            text: "¡Por supuesto! CAMNET tiene toda la experiencia necesaria para comprender tu negocio y así mismo,  generar una página web acorde a tus necesidades."
        },
        {
            title: "¿CAMNET realiza proyectos en todo el territorio Colombiano?",
            text: "Hemos realizado proyectos de todo tipo en todo el territorio nacional. Estamos capacitados para mover a todos nuestros asociados a cualquier parte de Colombia."
        },
        {
            title: "¿Cómo puedo cotizar el valor de mi proyecto?",
            text: "Para saber el costo de proyecto que tienes en mente puedes dirigirte a la sección de Contacto, allí en el formulario puedes enviarnos un mensaje y ¡nos pondremos en contacto contigo al instante!"
        },
        {
            title: "¿Ha trabajado CAMNET con instituciones públicas?",
            text: "Así es, CAMNET ha realizado proyectos tanto en universidades e instituciones públicas como privadas. "
        },
        {
            title: "¿CAMNET puede desarrollar proyectos que involucren tanto hardware como software?",
            text: "Claro que sí, hemos realizado proyectos en donde se ha requerido la intervención de hardware especializado para lograr el objetivo del cliente. No tenemos inconveniente en acoplar sistemas hardware a sistemas software desarrollado por nosotros o terceros."
        },
        {
            title: "¿Tu pregunta no está en esta lista?",
            text: "No te preocupes. Puedes tener contacto directo con nosotros entrando al apartado de Contacto ubicado en la barra superior de la página web. "
        } 
    ]

    let questions = []

    for(let i = 0; i < faqInfo.length; i++) {
        faqInfo[i].enum = "0" + (i + 1)
        questions.push(
            <Faq data={faqInfo[i]} key={faqInfo[i].title}></Faq>
        )
    }

    return (
        <div className="row main-6-container">
            <div>
                <p className="title black title">Preguntas frecuentes</p>
                <Link to="/Contact" className="link" onClick={scrollToTop}>
                    <p className="subtitle blue subtitle">Contáctanos para más información</p>
                </Link>
                </div>
            <div>
                {questions}
            </div>
        </div>
    )
}

export default Main_6;