import "./App.css";
import React, { useState } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Main from "./pages/Main/Main";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/AboutUs/AboutUs";
import Services from "./pages/Services/Services"

import Lottie from "lottie-react";
import Loading from "../src/Animations/nukaki-loading.json";
import FootprintPage from "./pages/Footprint/FootprintPage"
import Error404 from "./Components/Error404/Error404";
import Projects from "./pages/Projects/Projects";

function App(props) {
  const [loading, setLoading] = useState(props.showSplash === "true");

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  let content;

  switch (props.render) {
    case "main":
      content = <Main />;
      break;
    case "about-us":
      content = <AboutUs />;
      break;
    case "contact":
      content = <Contact />;
      break;
    case "projects":
      content = <Projects />
      break;
    case "services":
      content = <Services />
      break;
    case "footprint":
      content = <FootprintPage />;
      break;
    case "error404":
      content = <Error404 />;
      break;
    default:
      content = <Main />;
  }

  return (
    <>
      <div
        className={loading ? "splash-container-showed" : "splash-container-hidden"}>
        <Lottie className="animation" animationData={Loading} loop={false} />
      </div>

      <div className="App" style={{ display: loading ? "none" : "block" }}>
        <Navbar />
        <div className="background-container">{content}</div>
        <Footer />
      </div>
    </>
  );
}

export default App;
