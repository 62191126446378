import React from "react";
import "./Main_4.css";
import ServiceCard from "./ServiceCard";
import data from '../../../Utils/DataServices.json'

const Main_4 = () => {
  let servicesView = []

  data.forEach(item => {
    servicesView.push(
      <ServiceCard
        data={item}
        key={item.id}
      />
    )
  })

  return (
    <div className="main-4-container">
      <p className="title center-text black main-4-title">
        Diseñamos una solución a tus problemas con eficiencia
      </p>
      <p className="subtitle center-text main-4-subtitle black">
        Nuestros servicios
      </p>
      <div className="main-4-grid">
        {servicesView}
      </div>
    </div>
  );
};

export default Main_4;
