import React from 'react'
import './Services.css'
import Service from '../../Components/Services/ServiceComponent'
import data from '../../Utils/DataServices.json'
import CarouselComponent from '../../Components/Services/CarouselComponent'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Services = () => {
    const location = useLocation()
    const { state } = location.state || {state: 0}

    const title = "Nuestros servicios"

    const subtitle = "Nuestros servicios se dividen en 4 categorías principalmente, estas categorías son: Hardware, Software, Redes e Integraciones."

    let servicesView = []

    data.forEach(info => {
        servicesView.push(
            <Service data={info} key={info.id} />
        )
    })

    useEffect(() => {
        document.getElementById(state).scrollIntoView({ behavior: 'smooth' });
    }, [])

    return (
        <div className="services-container">
            <p className="services-title title black center-text">{title}</p>
            <p className="subtitle black services-subtitle">{subtitle}</p>
            <CarouselComponent folder="Partner" max={7} />
            <div className="services-grid ">
                {servicesView}
            </div>
        </div>
    )
}

export default Services;