import React, { useState, useEffect } from 'react'
import './Faq.css'
import plusIcon from '../../../Images/icons/add_icon.svg'
import closeIcon from '../../../Images/icons/close_icon.svg'

const Faq = (props) => {
    const [isTextVisible, setTextVisible] = useState(false);

    return (
        <div className="column">
            <div className="row faq-title-container" onClick={() => {setTextVisible(!isTextVisible); console.log("HOL;A")}}>
                <p className="subtitle blue center-text faq-enum">{props.data.enum}</p>
                <p className="title black center-text faq-title">{props.data.title}</p>
                <img className="faq-icon" src={isTextVisible ? closeIcon : plusIcon} alt="React Logo" />
            </div>
            
            <div className={isTextVisible ? "faq-visible" : "faq-invisible"}>
                <p className="subtitle black faq-text">{props.data.text}</p>
            </div>
            <div className="divider faq-divider"/>
        </div>
    )
}

export default Faq;