import React from "react";
import { useState } from "react";
import Main_7 from "../../Components/Main/Main_7/Main_7";
import "./Contact.css";

const Contact = () => {

  return (
    <>
      <Main_7/>
    </>
  );
  
};

export default Contact;
