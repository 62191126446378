import React from 'react'
import './FootprintComponent.css'

const FootprintComponent = (props) => {
    return (
        <div className="footprint-component-container" 
            style={{
                flexDirection: props.data.switch ? "row" : "row-reverse",
                background: props.data.switch ? "white" : "var(--background)"
            }}
        >
            <div className="footprint-page-column-container ordt">
                <p className="title black footprint-component-title ">{props.data.title}</p>
                <p className="subtitle black footprint-component-subtitle">{props.data.subtitle}</p>
            </div>
            <div className="center ordim">
                <img src={props.data.image} className="footprint-component-img footprint-page-column-container " alt="logo" />
            </div>
        </div>
    )
}

export default FootprintComponent