import React, { useEffect, useState } from "react";
import "./Main_5.css"
import leftArrow from "../../../Images/logos/arrow-left.svg"
import rightArrow from "../../../Images/logos/arrow-right.svg"


const Main_5 = () => {


    const imagenes = ["client_1.jpg", "client_2.jpg", "client_3.jpg", "client_4.png"];
    const nameClients = ["Pedro Gabriel Silva", "Mónica Bibiana Valderrama", "Leonardo David Escobar", "Antonio Granados"];
    const professionClient = ["Profesor de la Universidad Nacional de Colombia.", "Integrante del cuerpo de Bomberos", "Líder social", "Ingenerio de sistemas"]
    const comentsClient = [
        ' " Felicitaciones al equipo de CAMNET, por su profesionalidad y por seguir incentivando la tecnología como un ' + 'recurso valioso y necesario, actualmente, en el proceso de formación de ciudadanos y de grandes civilizaciones. "',
        ' " Disponen de un gran equipo de soporte y atención, son flexibles a las necesidades y requerimientos que se ' + 'pidan y brindan soluciones efectivas. Mi negocio creció con ayuda de una pagina web y pude expandir mis sucursales en poco tiempo. " ',
        ' " Gracias al proyecto en curso en Cartagena, se salvarán vidas y se estará dando visibilidad a las comunidades ' + 'más afectadas. Es un equipo que se preocupa por las personas del común y por sus necesidades. " ',
        ' " CAMNET es una empresa con 20 años de experiencia que sí utiliza tecnología de vanguardia. Posee un equipo ' + 'de profesionales altamente capacitados y dispuestos a atender en cualquier momento. " ']








    const [indiceSeleccionado, setIndiceSeleccionado] = useState(0);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(imagenes[0]);
    const [nombreSeleccionado, setNombreSeleccionado] = useState(nameClients[0]);
    const [profesionSeleccionada, setProfesionSeleccionada] = useState(professionClient[0]);
    const [comentarioSeleccionado, setComentarioSeleccionado] = useState(comentsClient[0]);
    const [loaded, setLoaded] = useState(false);



    useEffect(() => {
        const interval = setInterval(() => {
            nuevaImagen(indiceSeleccionado, imagenes)
        }, 5000)
        return () => clearInterval(interval);
    },)



    const nuevaImagen = (index, imagenes, next = true) => {
        setLoaded(false);
        setTimeout(() => {
            const condicion = next
                ? indiceSeleccionado < imagenes.length - 1
                : indiceSeleccionado > 0;
            const siguienteIndice = next
                ? condicion
                    ? indiceSeleccionado + 1
                    : 0
                : condicion
                    ? indiceSeleccionado - 1
                    : imagenes.length - 1;
            setIndiceSeleccionado(siguienteIndice);
            setImagenSeleccionada(imagenes[siguienteIndice]);
            setNombreSeleccionado(nameClients[siguienteIndice]);
            setProfesionSeleccionada(professionClient[siguienteIndice]);
            setComentarioSeleccionado(comentsClient[siguienteIndice]);
        }, 300);
    };

    const anterior = () => {
        nuevaImagen(indiceSeleccionado, imagenes, false);
    };

    const siguiente = () => {
        nuevaImagen(indiceSeleccionado, imagenes);
    };



    return (
        <div className="container-clients">
            <div className="content-clients" style={{ flex: "3" }}>
                <p className="title tm">¿Qué es lo que dicen los clientes sobre nosotros?</p>
                <p className="subtitle black"> La opinión de nuestros clientes es muy importante para nostros y así tener siempre una mejora continua.</p>
            </div>
            <div className="info-clients" style={{ flex: "4" }}>

                <div className="coment-client">
                    <p className="subtitle"> {comentarioSeleccionado}</p>
                </div>
                <div className="img-nav-client">


                    <div className="client" style={{ flex: "3" }}>
                        <img
                            src={require(`../../../Images/main_images/main_clients/${imagenSeleccionada}`)}
                            alt="camnet"
                            className={loaded ? "picture-client loaded" : "picture-client"}
                            onLoad={() => setLoaded(true)}
                        />


                        <div className="clients-names" >
                            <p className="subtitle tm" >{nombreSeleccionado}</p>
                            <p className="subtitle tm" >{profesionSeleccionada}</p>
                        </div>
                    </div>


                    <div className="coments-nav" style={{ flex: "1" }}>

                        <img src={leftArrow} className="coments-nav-logo" alt="left" onClick={anterior}></img>
                        <img src={rightArrow} className="coments-nav-logo" alt="right" onClick={siguiente}></img>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Main_5