import React from 'react'
import "./Main_3.css"
import main_3_1 from "../../../Images/main_images/main_3_11.png"
import main_3_2 from "../../../Images/main_images/main_3_2.jpg"
import main_3_3 from "../../../Images/main_images/main_3_3.jpg"
import { Link } from 'react-router-dom';

const Main_3 = (props) => {

    const scrollToTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    };

    return (

        <div className="main-3-container">

            <div className="title-main-3">
                <div className='title black' style={{ flex: "5" }}>Nuestros proyectos</div>
                <Link className="button subtitle black" style={{ flex: "2" }} to={"/contact"} onClick={scrollToTop}>Contáctanos</Link>
            </div>

            <div className="content-main-3">

                <div className="pry" style={{ flex: "8" }}>
                    <img src={main_3_1} alt="imagen_1" className='img-left' />

                    <div className="left-gradient">
                        <p className='subtitle left-text'>SISTEMA DE ALERTAS TEMPRANAS</p>
                        <p className='subtitle'>Ciudad de Cartagena</p>

                        <Link to={"/Projects/1"} state={{ project: 1 }} className="button title-pry">
                            Ver proyecto →
                        </Link>
                    </div>
                </div>


                <div className="subpry" style={{ flex: "4" }}>


                    <div className="pry-2">
                        <img src={main_3_2} alt="imagen_2" className='img-right' />
                        <div className="right-gradient">
                            <p className='subtitle left-text'>DOCUMENTACIÓN AUDIOVISUALES</p>
                            <p className='subtitle'>Universidad Distrital F.J.D.C</p>

                            <Link to={"/Projects/2"} state={{ project: 2 }} className="button subtitle ">
                                Ver proyecto →
                            </Link>
                        </div>
                    </div>

                    <div className="pry-3">
                        <img src={main_3_3} alt="imagen_2" className='img-right' />
                        <div className="right-gradient">
                            <p className='subtitle left-text'>ACCESS GRID</p>
                            <p className='subtitle'>Universidad Distrital F.J.D.C</p>


                            <Link to={"/Projects/3"} state={{ project: 3 }} className="button subtitle ">
                                Ver proyecto →
                            </Link>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    );
}

export default Main_3