import React from "react";
import main_1 from "../../../Images/main_images/main_1_1.png";
import "./Main_1.css";
import { Link } from "react-router-dom";
import CarouselComponent from "../../../Components/Services/CarouselComponent"
import logo from "../../../Images//logos/camnet_logo.png";

const Main_1 = () => {

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  };

  return (
    <section className="main1-container">
      <div className="main-1-container">
        <div className="main-1-left">
          <div className="title center">
            <img src={logo} alt="logo" className="logoCamnetMain" />
          </div>
          <div className="subtitle white" >
            Ofrecemos soluciones de valor con la integración de tecnología, obras civiles y servicios, por medio de alianzas con fabricantes líderes de tecnología
            a nivel mundial y de una amplia experiencia en múltiples sectores.

            <br /><br />
            Cumplimos 20 años de experiencia en el mercado Colombiano, con más de 100 clientes satisfechos y cobertura a nivel nacional.
          </div>
          <div className="main-1-buttons">
            <Link className="button subtitle" to={"/contact"} onClick={scrollToTop}>
              Contáctanos
            </Link>

            <Link className="button subtitle" to={'/Services/0'} state={{ state: 0 }} onClick={scrollToTop}>
              Servicios
            </Link>
          </div>
        </div>
        <div className="main-1-right center">
          <img src={main_1} alt="imagen1" className="main1-imagen-m" />
        </div>

      </div>
      <CarouselComponent folder="Partner" max={7} className="carrousel-partners" stylePartner={true} />

    </section>
  );
};

export default Main_1;
