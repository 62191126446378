import React from 'react'

const InputBox = ({ estado, cambiarEstado, tipo, placeHolder, expresionRegular, errorMessage }) => {

  const onChange = (e) => {
    cambiarEstado({ ...estado, campo: e.target.value });
  }

  const validacion = () => {

    if (expresionRegular) {
      if (expresionRegular.test(estado.campo) || estado.campo === "") {
        cambiarEstado({ ...estado, valido: true })

      } else {
        cambiarEstado({ ...estado, valido: false })
      }
    }
  }




  return (
    <>
      <input type={tipo}
        placeholder={placeHolder}
        className={(estado.valido && estado.campo !== "") ? "subtitle text-field-pass" : (estado.valido ? "subtitle text-field" : "subtitle text-field-error")}
        value={estado.campo}
        onChange={onChange}
        onKeyUp={validacion}
        onBlur={validacion} />

      <div className='error-message'>{estado.valido ? "" : errorMessage}</div>
    </>
  )
}

export default InputBox