import React from "react";
import "./Main_2.css";
import Stepper from "./Stepper";
import { Link } from 'react-router-dom'

const Main_2 = () => {
  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <div className="main-2-container">
      <div>
        <p className="title black">Cómo trabajamos</p>

        <p className="subtitle black">
          Estamos interesados en poder brindarte las herramientas necesarias para impulsar
          tu empresa al siguiente nivel con ayuda de la tecnología. <br /> <br />
          Aquí podrás ver la ruta de nuestras soluciones.
        </p>
<br />
        <Link className="button black subtitle" to={'/Services/1'} state={{ state: 0 }} onClick={scrollToTop}>
          Ver servicios →
        </Link>


      </div>

      <div className="main-2-grid white">
        <Stepper number="01" title="Planeación" subtitle="Camnet te asesora en cada etapa de tu proyecto, incluso si solo tienes una idea. Nuestro objetivo es lograr plasmar de la mejor manera tus requerimientos." />

        <Stepper number="02" title="Diseño" subtitle="Contamos con diseñadores, expertos en experiencias (UX) e interfaces de usuario (UI), creando proyectos intuitivos, fáciles de manejar y en base a cualquier necesidad." />

        <Stepper number="03" title="Estrategia" subtitle="No tenemos competencia en tiempos de desarrollo. Ponemos en práctica métodos complejos para que tengas tu proyecto a tiempo." />

        <Stepper number="04" title="Desarrollo" subtitle="Usando las nuevas tecnologías, somos capaces de desarrollar proyectos complejos y en un tiempo record." />
      </div>
    </div>
  );
};

export default Main_2;
