import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css"
import { HashRouter as Router, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router >
      <Routes>
        <Route path="/" element={<App render="main" showSplash="true" />} />
        <Route path="/services/:state" element={<App render="services" />} />
        <Route path="/projects/:project" element={<App render="projects" />} />
        <Route path="/about-us" element={<App render="about-us" />} />
        <Route path="/contact" element={<App render="contact" />} />
        <Route path="/footprint/:state" element={<App render="footprint" />} />
        <Route path="*" element={<App render="error404" />} />
      </Routes>
    </Router>



  </React.StrictMode>
);