import "./Projects.css"
import ProjectCard from '../../Components/ProyectCard/ProjectCard'
import project_1 from "../../Images/main_images/main_3_1.PNG"
import project_2 from "../../Images/main_images/main_3_2.jpg"
import project_3 from "../../Images/main_images/main_3_3.jpg"
import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Projects = (props) => {

    const project1 = useRef();
    const project2 = useRef();
    const project3 = useRef();


    const location = useLocation()
    const { project } = location.state || {state: 0}




    useEffect(() => {
        console.log(project)
        switch (project) {
            case 1:
                project1.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 2:
                project2.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 3:
                project3.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                project1.current.scrollIntoView({ behavior: 'smooth' });
                break;
        }

    }, [])


    return (
        <>
            <section ref={project1} ></section>
            <ProjectCard
                title="SISTEMA DE ALERTAS TEMPRANAS"
                subtitle="Ciudad de Cartagena"
                description="Con la finalidad de apoyar a los gestores de riesgo de la ciudad de Cartagena y de reducir la probabilidad de desastres, especialmente en
                aquellas comunidades expuestas ante una amenaza constante, se propuso la elaboración e implementación de un sistema de información a través de una red de 
                estaciones meteorológicas con un sistema de alertas tempranas  y con un mapa interactivo. La propuesta constituirá un mecanismo articulado de gestión de información, 
                análisis oportuno, toma de decisiones y acciones, que permiten alertar, monitorear y apoyar la toma de decisiones efectuada por actores técnicos,
                funcionarios públicos y la sociedad civil. "

                item_1="Conectividad en tiempo real."
                item_2="Beneficio para campesiones y ganaderos de la zona."
                item_3="Beneficio para la Gestión del Riesgo y la fuerza pública."
                item_4="Prevención de desastres por medio del monitoreo de riesgo de los eventos que suceden y que pueden suceder."
                image={project_1}
            />

            <section ref={project2} ></section>
            <ProjectCard
                title="DOCUMENTACIÓN AUDIOVISUALES"
                subtitle="Universidad Distrital F.J.D.C"
                description="Se documentó la implementación de automatización en el auditorio de la Universidad Distrital Francisco José de Caldas, como una solución
                tecnológica, por medio del registro fotográfico y escrito del funcionamiento de: servidores de audio, servidores de sonido, cuarto de control, televisores, video
                proyectores,cámaras para videoconferencia, sistema de micrófonía de última generación y sistema de amplificación."
                item_1="Empleo de la tecnología para la realización de actividades con poca intervención humana."
                item_2="Garantizar la interrelación e interconexión del auditorio con las personas mediante los elementos tecnológicos."
                item_3="Documentación escrita descriptiva y fotográfica sobre el funcionamiento de los componentes de la automatización."
                item_4="Comodidad y simplicidad en la realización de actividades."
                image={project_2}
            />

            <section ref={project3} ></section>
            <ProjectCard
                title="SALA ACCESS GRID"
                subtitle="Universidad Distrital F.J.D.C"
                description="Por medio de la adecuación de un salón de clases de la Facultad de Ingeniería de la Universidad Distrital Francisco José
                de Caldas, se diseñó el primer nodo de Access GRID de la universidad y el tercero de Colombia, mediante el empleo de equipos y materiales de alta calidad. 
                Con la finalidad de apoyar el proceso de comunicación entre diferentes grupos, la investigación colaborativa y así mismo, el proceso de formación impartido
                en la institución educativa."
                item_1="Facilita la intercomunicación entre grupos de trabajo geográficamente distantes."
                item_2="Reducción de costos en la adquisición de equipos, debido al aprovechamiento de los recursos existentes."
                item_3="Permite el empleo de recursos computacionales con alta capacidad de procesamiento, almacenamiento y compartición."
                item_4="Brinda confiabilidad, balance de recursos y una mejor administración de las subtareas."
                image={project_3}
            />

        </>
    )
}

export default Projects