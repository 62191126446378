import "./MainStyles.css";
import Main_1 from "../../Components/Main/Main_1/Main_1";
import Main_2 from "../../Components/Main/Main_2/Main_2";
import Main_3 from "../../Components/Main/Main_3/Main_3";
import Main_4 from "../../Components/Main/Main_4/Main_4";
import Main_5 from "../../Components/Main/Main_5/Main_5";
import Main_6 from "../../Components/Main/Main_6/Main_6";
import Main_7 from "../../Components/Main/Main_7/Main_7";
import Main_8 from "../../Components/Main/Main_8/Main_8";
import CarouselComponent from "../../Components/Services/CarouselComponent"

function Main() {
  return (
    <>
      <Main_1 />
      <Main_2 />
      <Main_3 />
      <Main_4 />
      <Main_5 autoplay="true" />
      <Main_6 />
      <Main_7 />
      <Main_8 />
      <CarouselComponent folder="Client" max={14} stylePartner={true} />
    </>
  );
}

export default Main;
