import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../Images/logos/camnet_logo.png";
import { Link } from 'react-router-dom';


import { ReactComponent as MenuOutlined } from "../../Images/icons/align_justify_icon.svg"
import { ReactComponent as Close } from "../../Images/icons/x_icon.svg"




const Navbar = (props) => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);


  const scrollToTop = () => {
    handleClick();
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  };



  return (
    <div className={color ? "header header-bg " : "header header-bg"}>
      <nav className="navbar">
        <a href="/">
          <img src={logo} alt="logo" className="logoCamnet" />
        </a>


        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <Close className="iconMenu" />
          ) : (
            <MenuOutlined className="iconMenu" />
          )}
        </div>


        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item subtitle">
            <Link to={'/'} className={color ? " nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Inicio
            </Link>
          </li>

          <li className="nav-item  subtitle">
            <Link to={'/Services/1'} state={{ state: 0 }} className={color ? " nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Servicios
            </Link>
          </li>

          <li className="nav-item  subtitle">
            <Link to={"/Projects/1"} state={{ project: 1 }} className={color ? "nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Proyectos
            </Link>
          </li>

          <li className="nav-item subtitle">
            <Link to={'/Footprint/1'} state={{ state: 1 }} className={color ? "nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Huella
            </Link>
          </li>

          <li className="nav-item subtitle">
            <Link to="/about-us" className={color ? "nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Nosotros
            </Link>
          </li>

          <li className="nav-item subtitle">
            <Link to={'/Contact'} className={color ? "nav_bl" : "nav_bl"} onClick={scrollToTop}>
              Contáctanos
            </Link>
          </li>
        </ul>
      </nav>
    </div >
  );
};

export default Navbar;
