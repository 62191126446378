import React from 'react'
import "./Error404.css"
import error_404 from "../../Images/error_404.png"


const Error404 = () => {
    return (
        <>

            <div className="container-error404" >
                <div className="container-image-error">
                    <img src={error_404} alt="Error 404" className="image-error" />
                </div>
                <div className="container-error-mesage">
                        <div className="title"> ¡Vaya!</div>
                        <div className="title" style={{ textAlign: "justify" }}>Parece que no hemos podido encontrar la página que buscas</div>
                        <div className="subtitle">Código de error: 404</div>
                </div>
            </div>


        </>
    )
}

export default Error404