import React from "react";
import "./Footer.css";
import logo from "../../Images/logos/camnet_logo.png";
import facebook from "../../Images/logos/FB.png";
import instagram from "../../Images/logos/IG.png";
import twitter from "../../Images/logos/TW.png";
import { Link } from "react-router-dom";
import Constants from '../../Utils/Constants.json'

const Footer = (props) => {

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  };

  const emailLink = "mailto:" + Constants.contact_email + "?Subject=Quiero%20una%20cotización%20por%20favor."







  return (
    <>

      <div className="footer-container">
        <div className="footer-column-l">
          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <img src={logo} alt="logo" className="footer-logo" />
          </div>
          <div className="subtitle white text-footer">
            Cumplimos 20 años de experiencia en el mercado Colombiano, con más de 100 clientes satisfechos y cobertura a nivel nacional.
          </div>

          <div className="footer-contact">
            <div>
              <a
                className="subtitle black link"
                href={emailLink}
                target="_blank"
              >Correo:  <br />{Constants.contact_email}</a>
            </div>

            <div>
              <a href="https://goo.gl/maps/poWz6wF1z3sFFeh98" target="_blank" className="subtitle black  link">Dirección: <br /> {Constants.adress}</a>
            </div>

            <div>
              <a href={"tel:" + Constants.contact_number_string} target="_blank" className="subtitle black link">Teléfono: <br /> {Constants.contact_number_string}</a>
            </div>

            <div>
              <a href={"tel:" + Constants.landline} target="_blank" className="subtitle black  link">Teléfono fijo: <br /> {Constants["landline-number"]}</a>
            </div>


          </div>
        </div>

        <div className="footer-column-r">
          <br />
          <p className="title white tm">Nuestras Redes Sociales</p>
          <p className="subtitle white tm">
            Búscanos en Facebook, Instagram ó Twitter, y entérate de nuestras ultimas noticias y desarrollos.
          </p>
          <div className="redes" style={{ flex: "2" }}>
            <ul className="social-network-list">
              <li>
                <a href="https://www.facebook.com/CamnetColombia/" target="_blank" className="fab fa-facebook-f">
                  <img src={facebook} alt="fb" style={{ padding: "5px" }} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/camnet_co" className="fab fa-instagram" target="_blank">
                  <img src={instagram} alt="ig" style={{ padding: "10px" }} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/CamnetPrincipal" className="fab fa-twitter" target="_blank">
                  <img src={twitter} alt="tw" style={{ padding: "10px" }} />
                </a>
              </li>

            </ul>


          </div>

        </div>

      </div>



      <div className="map-camnet">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d994.1384484487082!2d-74.128305!3d4.6733396!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9c83e7f3f18f%3A0xaf645f6a786a63d5!2sCl.%2023i%20Bis%20%2393-20%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1678642981992!5m2!1ses!2sco" style={{ border: "0", flex: "1" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className="footer-copyright" >

<br className="spacemap" />

        <div className="footer-links">
          <Link className="subtitle black  link space-footer-cr" to={'/'} onClick={scrollToTop}>Inicio</Link>
          <Link className="subtitle black  link space-footer-cr" to={'/Services/1'} state={{ state: 0 }} onClick={scrollToTop}>Servicios</Link>
          <Link className="subtitle black  link space-footer-cr" to={"/Projects/1"} state={{ project: 1 }} onClick={scrollToTop}>Proyectos</Link>
          <Link className="subtitle black  link space-footer-cr" to={"/footprint/0"} state={{ state: 1 }} onClick={scrollToTop}>Huella </Link>
          <Link className="subtitle black  link space-footer-cr" to={"/about-us"} onClick={scrollToTop}>Nosotros</Link>
          <Link className="subtitle black  link space-footer-cr" to={"/contact"} onClick={scrollToTop}>Contáctanos</Link>
        </div>
<br />
        <p className="subtitle black center ">Copyright 2022, Camnet</p>
      </div>
    </>
  );
};


export default Footer;
