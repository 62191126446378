import React from 'react'
import './ServiceComponent.css'

const Service  = (props) => {
    let items = []

    props.data.items.forEach(item => {
        items.push(
            <li>{item}</li>
        )
    })

    return (
        <div id={props.data.id} className="service-container">
            <div className="service-img-background center">
                <img src={props.data.img} alt="imagen project" className="service-img"/>
            </div>

            <p className="title service-title black">{props.data.title}</p>

            <p className="subtitle black" style={{ display: props.data.subtitle ?  'block' : 'none' }}>{props.data.subtitle}</p>

            <div className="subtitle service-subtitle black">{items}</div>
        </div>
    )
}

export default Service;