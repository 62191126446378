import React from 'react'
import './Footprint.css'
import { Link } from "react-router-dom";

const Footprint = (props) => {
    const MAX_DESCRIPTION = 50

    const scrollToTop = () => {
        setTimeout(function () {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
      };

    let description = props.data.subtitle

    if (description.length > MAX_DESCRIPTION) {
        description = description.substring(0, MAX_DESCRIPTION) + "..."
    }

    return(
        <Link to="/footprint/0" state={{ state: props.data.id }} className="link">
            <div className="footprint-container column">
                <img src={props.data.image} alt="img" className="footprint-img" />
                <p className="subtitle footprint-date black">{props.data.date}</p>
                <p className="title footprint-title black">{props.data.title}</p>
                <p className="subtitle footprint-subtitle black">
                    {description}
                </p>

                <div className="footprint-button-container">
                    <div className="button-services footprint-button">Leer más →</div>
                </div>
            </div>
        </Link>
    )
}

export default Footprint;