import React from 'react'
import "./ProjectCard.css"


const ProjectCard = ({ title, subtitle, description, item_1, item_2, item_3, item_4, image }) => {
  return (
    <>
      <div className="container-ProjectCard">
        <div className="title blue-dark titlep" >{title} <br /> {subtitle}</div>
        <div className="subtitle blue-dark descriptionp">{description}</div>
        <br className='spacep' />
        <ul className='list-characteristics-project blue-dark subtitle listp'>
          <li className='characteristics-project blue-dark'>{item_1}</li>
          <br />
          <li className='characteristics-project blue-dark'>{item_2}</li>
          <br />
          <li className='characteristics-project blue-dark'>{item_3}</li>
          <br />
          <li className='characteristics-project blue-dark'>{item_4}</li>
        </ul>
        <br className='spacep' /><br className='spacep' />
        <div className="background-project imgp">
          <img src={image} alt="imagen project" className="project-image" />
        </div>
      </div>



    </>
  )
}

export default ProjectCard