import React from 'react'
import FootprintComponent from '../../Components/FootprintPage/FootprintComponent'
import "./AboutUs.css"
import { Link } from "react-router-dom";
import President from "../../Images/PresidentCamnet1.png";

const AboutUs = () => {

  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

  return (
    <section className="about-container">
      <div className="main-1-container">
        <div className="main-1-left">
          <div className="title center-text white">¿Quiénes somos?</div>
          <br />
          <div className="subtitle white" >Integramos tecnologías en alianza a fabricantes mundiales líderes, automatización, obras civiles
            y servicios para ofrecer soluciones de gran valor.
          </div>
          <ul className='list-characteristics white'>
            <li className='subtitle'>Cumplimos 20 años de experiencia en el mercado Colombiano.</li> 
            <br />
            <li className='subtitle'>Partner Ortronics, AMP, HP, Microsoft Y  DELL desde 1999.</li>
            <br />
            <li className='subtitle'>Partner  McAfee desde 2005.</li>
            <br />
            <li className='subtitle'>Estaciones hidrometeorológicas.</li>
            <br />
            <li className='subtitle'>Contamos con cobertura a nivel nacional.</li>
            <br />
            <li className='subtitle'>Soluciones llave en mano.</li>
            <br />
            <li className='subtitle'>Fotogrametría con drones.</li>
          </ul>

<br />
            <Link className="subtitle link" to={"/contact"} onClick={scrollToTop}>
              <div className="button">Contáctanos</div>
            </Link>

        
        </div>
        
        <div className="container-imgAbout" >
          <img src={President} alt="imagen1" className="imgAboutUs" />
        </div>

      </div>



    </section>
  )
}

export default AboutUs