import React from "react";
import "./ServiceCard.css";
import { Link } from 'react-router-dom';

const ServiceCard = (props) => {
  const MAX_SUBTITLE = 120;
  let subtitle = props.data.subtitle;

  if (subtitle.length > MAX_SUBTITLE) {
    subtitle = subtitle.substring(0, MAX_SUBTITLE) + "..."
  }

  return (
    <Link to={"/Services/"+props.data.id} state={{ state: props.data.id }} className="link">
      <div className="service-card-container">
        <div className="center">
          <img src={props.data.img} alt="logo" className="service-img" />
        </div>
        <p className="title red service-card-title">{props.data.title}</p>
        <p className="subtitle black">{subtitle}</p>
      </div>
    </Link>

  );
};

export default ServiceCard;
