import React from 'react'
import './Main_8.css'
import Footprint from './Footprint'
import data from '../../../Utils/DataFootprint.json'

const Main_8 = () => {
    let footprints = []

    for(let i = 0; i < data.length; i++) {
        footprints.push(<Footprint data={data[i]} key={data[i].id}/>)
    }

    return (
        <div className="main-8-container">
            <p className="title black">Huella</p>
            <div className="main-8-footprints">
                {footprints}
            </div>
        </div>
    )
}

export default Main_8;