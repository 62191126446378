import React from "react";
import "./Stepper.css";

const Stepper = (props) => {
  return (
    <div className="stepper-container">
      <div className="stepper-number">
        <p className="subtitle center-text">{props.number}</p>
      </div>

      <p className="title black">{props.title}</p>

      <p className="subtitle black">{props.subtitle}</p>
    </div>
  );
};

export default Stepper;
