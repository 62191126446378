import React from 'react'
import './FootprintPage.css'
import FootprintComponent from '../../Components/FootprintPage/FootprintComponent'
import data from '../../Utils/DataFootprint.json'
import CarouselComponent from '../../Components/Services/CarouselComponent'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const FootprintPage = () => {
    let { state } = useLocation().state || {state: 0}

    let items = []

    useEffect(() => {
        document.getElementById(state).scrollIntoView();
    }, [])

    for (let i = 0; i < data.length; i++) {
        let temp = data[i]
        temp.switch = (i % 2 == 0)

        items.push(
            <section id={temp.id} key={temp.id}>
                <FootprintComponent data={temp} key={temp.id}/>
            </section>
           
        )
    }

    return(
        <div className="footprint-page-container">
            <p className="title black footprint-page-title">Nuestra huella</p>
            <p className="subtitle black footprint-page-subtitle">
                A continuación verás algunos casos de éxito que fueron planeados, diseñados y desarrollados por nosotros.
            </p>

            {items}

            <CarouselComponent folder="Client" max={14}/>
        </div>
    )
}

export default FootprintPage